@use "styles/variables";

.container {
  max-width: 50rem;

  .heading {
    align-items: center;
    color: variables.$text-color-700;
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem 1rem;
    opacity: 0.75;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.5rem;
      position: relative;

      &::after {
        background-color: variables.$text-color-700;
        content: "";
        display: block;
        height: 1px;
        left: 50%;
        position: absolute;
        transition: left 0.2s, width 0.2s;
        width: 0;
      }

      &:hover::after {
        left: 0;
        width: 3.6rem;
      }
    }
  }

  .list {
    list-style: none;

    .item {
      display: inline-block;
      margin: 0.5rem;

      button {
        background-color: transparent;
        border: thin solid variables.$primary-color;
        border-radius: 0.5rem;
        color: variables.$text-color-700;
        cursor: pointer;
        font-size: 1.5rem;
        padding: 0.5rem;
        transition: background-color 0.2s, color 0.2s;
        display: flex;
        align-items: center;
        gap: 1rem;

        &:hover,
        &.selected {
          background-color: variables.$primary-color-200;
          color: variables.$primary-color;
        }

        &.selected:hover {
          background-color: variables.$primary-color-300;
        }

        .blog-count {
          width: 2rem;
          padding: 0.5rem;
          border-radius: 0.5rem;
          display: inline-block;
          background-color: variables.$primary-color-500;
          color: variables.$background-color;
          font-size: 1rem;
        }
      }
    }
  }
}
