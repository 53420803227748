$background-color: #f0f9ff;

$green-color-500: #22c55e;

$modal-background-color: #94a3b880;

$primary-color: #4f46e5;
$primary-color-500: #6366f1;
$primary-color-300: #a5b4fc;
$primary-color-200: #c7d2fe;
$primary-color-100: #e1e6fb;

$red-color-500: #f43f5e;

$text-color-700: #334155;
$text-color-600: #475569;

$white-color: #ffffff;
