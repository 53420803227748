@use "styles/variables";

.list {
  list-style: none;

  .item {
    margin-bottom: 2.4rem;
  }

  .more {
    background-color: variables.$primary-color-500;
    color: variables.$background-color;
    padding: 0.5rem 1rem;
    margin-top: 2rem;
    display: inline-block;
  }
}
