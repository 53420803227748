@use "styles/variables";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Noto Sans", sans-serif;
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: variables.$background-color;
  font-size: 1.6rem;
}

a {
  text-decoration: none;
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 8.2rem 3rem 1fr auto;
  margin: auto;
  max-width: 132rem;
  min-height: 100vh;
}

.banner {
  max-width: 132rem;
  margin: 1rem auto 0;
  text-align: center;
  padding: 0.75rem 0;
  color: variables.$white-color;
  background: linear-gradient(
    90deg,
    variables.$primary-color 0%,
    variables.$primary-color-500 50%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  a {
    border: thin solid variables.$white-color;
    background: variables.$white-color;
    color: variables.$primary-color;
    border-radius: 0.5rem;
    padding: 0.25rem 1rem;
    font-weight: 600;
    text-decoration: none;
  }
}

header {
  grid-area: 1/1/1/-1;
  padding: 2rem 2rem 0;

  .heading {
    color: variables.$text-color-700;
    font-size: 5rem;
  }
}

main {
  padding: 0 2rem 2rem;
  min-height: 62rem;

  &.main-home {
    border-left: thin dotted variables.$text-color-700;
    border-right: thin dotted variables.$text-color-700;
    grid-area: 3/4/3/10;
  }

  &.main-add-blog {
    grid-area: 3/1/3/-1;
  }

  &.main-about {
    grid-area: 3/1/3/10;
    color: variables.$text-color-600;

    h2 {
      margin: 4rem 0 1rem;
      opacity: 0.75;
    }
  }

  &.main-stripe {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 100rem;
    margin: 0 auto;
    color: variables.$text-color-700;

    h1,
    h4,
    h3 {
      color: variables.$text-color-600;
    }

    strong {
      font-weight: 600;
    }

    a {
      color: variables.$primary-color;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
        text-decoration-style: dotted;
      }
    }

    ol {
      margin-left: 2rem;

      li {
        margin: 1rem 0;
      }
    }

    .email {
      margin-top: 7rem;
      margin-bottom: 3rem;
    }
  }
}

aside {
  &.left {
    grid-area: 3/1/3/4;
    padding: 0 2rem 2rem;
  }

  &.right {
    grid-area: 3/10/3/-1;
    padding: 0 2rem 2rem;
  }
}

footer {
  border-top: thin dotted variables.$text-color-700;
  display: grid;
  grid-area: 4/1/4/-1;
  grid-template-columns: repeat(12, 1fr);
  padding: 4rem 4rem 8rem;
}

@media screen and (max-width: 45em) {
  html {
    font-size: 50%;
  }

  main {
    &.main-home {
      border-right: none;
      grid-area: 3/1/3/-1;
    }
  }

  aside {
    display: none;
  }
}

@media screen and (max-width: 39.5em) {
  .banner {
    display: none;
  }

  .container {
    grid-template-rows: 12rem 5rem 1fr auto;
  }

  header {
    grid-area: 1/1/1/-1;
    padding: 2rem 2rem 0;
  }

  main {
    padding: 0 2rem 2rem;

    &.main-add-blog {
      grid-area: 2/1/4/-1;
    }
  }

  footer {
    padding: 2rem 2rem 4rem;
  }
}
