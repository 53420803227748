@use "styles/variables";

.logo-container {
  grid-area: 1/1/1/4;

  img {
    width: 12rem;
    height: 12rem;
    opacity: 0.88;
  }

  p {
    color: variables.$text-color-600;
    margin-top: 1rem;

    a {
      color: variables.$primary-color;
    }
  }

  @media screen and (max-width: 45em) {
    img {
      width: 10rem;
      height: 10rem;
    }

    & {
      grid-area: 1/1/1/6;
    }
  }
}

.list {
  list-style: none;

  &.page-links {
    grid-area: 1/10/1/11;
  }

  &.social-links {
    grid-area: 1/12/1/-1;
  }

  .item {
    margin-bottom: 1rem;

    a {
      color: variables.$text-color-600;
      text-decoration: none;

      &:hover {
        color: variables.$text-color-700;
      }
    }
  }

  @media screen and (max-width: 45em) {
    &.page-links {
      grid-area: 1/9/1/11;
    }

    &.social-links {
      grid-area: 1/11/1/-1;
    }
  }

  @media screen and (max-width: 39.5em) {
    &.page-links {
      grid-area: 1/8/1/10;
    }
  }
}
